import React, {Component,useState} from 'react';
import '../tools/css/BtnEdit.css';

//Componente para boton editar

class BtnEdit extends Component {   
      

      render(){ 
          
     
       return (   
       
          <div >

              <button className="btn btn-edit">
                <svg width="23" height="18" viewBox="3 3 10 10" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                </svg>
              </button>                    
           
          </div>    
        
      )
  
    }
    
  }  
  
  
  export default BtnEdit;