import React, {useState} from 'react';
import '../tools/css/Footer.css';

var Nombre_Usuario =sessionStorage.getItem('nombre_usua'); 

 function Footer(){   

    return(

      <footer className="main-footer" id="footer">
          <strong>Copyright &copy; 2023 Medicall24  <a href="https://medicall24.com.co/" target="_blank"> Terminos y condiciones</a> - </strong>
             Todos los derechos reservados. Version 2.0.4
            <div className="float-right d-none d-sm-inline-block">
            <b>Usuario:&nbsp; {Nombre_Usuario ? Nombre_Usuario.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))): ''}</b>
          </div>
     </footer>

     
        
    )
}

export default Footer;