import React, {Component} from 'react';
import '../tools/css/ContratosAdd.css';
import Date from '../components/DataPicker';
import BtnSave from '../components/BtnSave';
import BtnCancel from '../components/BtnCancel';
import Eps from '../API/Eps';
import $ from 'jquery'; // <-to import jquery
import XLSX from '../tools/XLSX/EstructuraBD_EPS.xlsx';
import 'bootstrap';





class ContratosAdd extends Component {

  constructor()
    {
     super()
      this.state={
        show:false
      }
    }
    
    handleModalnewcontrato()
    {
      this.setState({show:!this.state.show})
      $('#modal-newcontrato').modal('show'); // <- to show modal crear contrato 
    }

    handleModaleditcontrato()
    {
      this.setState({show2:!this.state.show2})
      $('#modal-editcontrato').modal('show'); // <- to show modal editar contrato 
    }
       

    render(){  

     return (

     
      <div className="content-wrapper">

          <br/>
  

  <div className="col-12 col-sm-12">
  <div className="row">

          <div className="col-sm-10">

             <div className="text-center" id="title"> <h4> <b> CREAR CONTRATOS </b>  </h4> </div>

          </div>

         

      </div>
  <div className="card card-primary card-outline card-tabs">
    <div className="card-header p-0 pt-1 border-bottom-0">
      
    </div>
    <div className="card-body">
      <div className="tab-content" id="custom-tabs-three-tabContent">
       
          
        <div className="card">
  
            {/* /.card-header */}
            <div className="card-body">

                <div className="row">
                                
                    <div className="col-md-6">
                        <div className="form-group">                    
                            <label >Seleccione la EPS con la cual se creará el contrato:</label>
                            <Eps/>                     
                        </div>                 
                    </div>
                                
                    <div className="col-md-6">                
                        <div className="form-group">                    
                            <label >Tipo de servicio:</label>
                            <Eps/>                     
                        </div>
                            
                    </div>
                            
                </div>


                <div className="row">
                                
                    <div className="col-md-6">
                        <div className="form-group">                    
                              <label >Fecha inicio:</label>
                              <Date/>
                        </div>
                
                    </div>

                    <div className="col-md-6">                
                        <div className="form-group">                    
                                <label >Fecha terminación:</label>
                                <Date/>
                        </div>
                
                    </div>
                
                </div>

              <br/>

                <p> Para la creación del contrato puede cargar de inmediato o más tarde si así lo desea el archivo excel (.XLSX) con los usuarios suministrado por la EPS que serán atendidos por su entidad, tenga en cuenta que debe cumplir con los requisitos y la estrutura necesaría para que puedan ser cargados correctamente.

                <br/>
                
                 Puede descargar <a  href={XLSX} > 
                 <b>
                   <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-cloud-download" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/>
                    <path fill-rule="evenodd" d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/>
                   </svg> Aquí
                 </b>
                 </a> la estructura del archivo. Recuerde que está estructura no puede ni debe ser modificada, solo relacione en cada fila del archivo el tipo y número de identificación para cada usuario. </p>

                         
            
                 <label for="file-upload" className="custom-file-upload">
                   <svg width="1.2em" height="1.2em" viewBox="0 0 16 16" class="bi bi-cloud-arrow-up" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
                    <path fill-rule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z"/>
                   </svg> Subir archivo
                 </label>
                   <input id="file-upload" type="file"/>                  

                
                   <div className="modal-footer">        
                   <BtnSave/>
                   <BtnCancel/>
                   </div>


    
            </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}

       
      </div>
    </div>
    {/* /.card */}
  </div>
</div>





</div>


      
      
    )

  }
  
}



export default ContratosAdd;