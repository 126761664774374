import React from  'react';
import ReactDOM from 'react-dom';
import App from './App';
import App_Movil from './App_Movil';

//import 'bootstrap/dist/css/bootstrap.css'


const container = document.getElementById('root')

if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    ReactDOM.render(<App_Movil/>,container)
}else{

    ReactDOM.render(<App/>,container)

}


