import React, {Component} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types'

//Componente para listar en select los contratos consumido en API

class Contract extends Component {   

    constructor()
    {
     super()
      this.state={        
        contrato: [],
        id_contrato:''
      }
    }

    componentDidMount(){    
    
        axios.get("https://medicallapi-dev.azurewebsites.net//api/Contracts/List")
        .then((response) => {
          console.log(response);
          this.setState({contrato: response.data.data})
        })
        .catch((error) => {
          console.log(error);
        })
      } 
      
      handleChange = (e) =>{
        this.setState({id_contrato:e.target.value})
        //console.log(e.target.value)
        //var med=(e.target.value)
        //alert(med)
      } 
      

    render(){
      const {handleridcontra} = this.props  
      const {id_contrato} = this.state
        
   
     return (   
     
        <div >

                <select onChange={this.handleChange} onClick={()=>handleridcontra(id_contrato)} value={this.state.id_contrato}  className="form-control" id="id_contrato">
                    <option  value="null"> Seleccione...</option> ,
                    {this.state.contrato.map(item=>                              
                    <option key={item.id} value={item.id}> {item.name.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                    )}
                            
                </select>                    
         
        </div>    
      
    )

  }
  
}  

Contract.propTypes = {
  handleridcontra:PropTypes.func.isRequired
}


export default Contract;