import React, {useState} from 'react';
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import {Link} from 'react-router-dom';

import {IconContext} from 'react-icons';
import img from '../tools/images/logoblanco.png';



function Navbar(){

    const [sidebar, setSidebar]=useState(true);

    const showSidebar = () => setSidebar(!sidebar);

    return(
        <> 

        <input type="checkbox" id="check"></input>

        <IconContext.Provider value={{color:'#fff'}}>
            <div className="navbar">
               <label for="check"  >
                <i className="FaIcons.FaBars" id="sidebar_btn"> 
                    <svg width="40" height="40" viewBox="3 10 15 1" class="bi bi-justify" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
                    </svg>
                </i>  
                   <a  href="/home" className="navbar-brand"> <img src={img} alt="logo"  width= "155"/> </a>   
               </label> 
               
                <nav className="navbar navbar-expand-lg navbar-light bg-light" id="navdrop"> 
                     
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                      <ul className="navbar-nav">
                        
                        <li className="nav-item dropdown">
                          <a className="nav-link dropdown-toggle text-light" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Configuración
                          </a>
                          <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <a className="dropdown-item" href="#">Action</a>
                            <a className="dropdown-item" href="#">Another action</a>
                            <a className="dropdown-item" href="#">Something else here</a>
                          </div>
                        </li>
                      </ul>
                    </div>
                </nav>         
            </div>
           

            <nav className={sidebar ? "nav-menu active" : "nav-menu"} id="sidebar">
                <ul className="nav-menu-items" >
                    
                   
                </ul>
            </nav>

            </IconContext.Provider>

        </>
    );
}

export default Navbar;