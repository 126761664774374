import React, {Component} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types'
import $ from 'jquery'; // <-to import jquery

//Componente para listar en select de profesionales consumido en API

class Profesionales extends Component {   

    constructor(props)
    {
     super(props)
      this.state={        
        profesionales: [],
        id_profesional: '0'
      }
    }

   
    componentDidMount=async()=>{       
      
    
      await axios.get("https://medicallapi-dev.azurewebsites.net//api/Professionals")
        .then((response) => {
          console.log(response);
          this.setState({profesionales: response.data})
        })
        .catch((error) => {
          console.log(error);
        })
      }  
      
      handleChange = (e) =>{
        this.setState({id_profesional:e.target.value})
       
        //console.log(e.target.value)
        //var med=(e.target.value)
        //alert(med)
      }     

    render(){ 

      const {handleridprofesional} = this.props  
      const {id_profesional} = this.state
        
   
     return (   
     
        <div>

                <select onChange={this.handleChange} onClick={()=>handleridprofesional(id_profesional)} value={this.state.id_profesional} className="form-control" id="api_profesional" name="profe" >
                    
                    <option  value="0"> Seleccione...</option> ,
                    {this.state.profesionales.map(item=>                              
                    <option key={item.id} value={item.id}>  {item.user.name1 ? (item.user.name1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' }  {item.user.name2 ? (item.user.name2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} { item.user.lastname1 ? (item.user.lastname1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' } { item.user.lastname2 ? (item.user.lastname2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' }</option>  
                    )}
                            
                </select>                    
         
        </div>    
      
    )

  }
  
} 

Profesionales.propTypes = {
  handleridprofesional:PropTypes.func.isRequired
}


export default Profesionales;