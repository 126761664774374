import React, {Component,useState} from 'react';
import '../tools/css/BtnEdit.css';


//Componente para boton Delete

class BtnDelete extends Component {   
      

      render(){ 
          
     
       return (   
       
          <div >

              <button  className="btn btn-edit">
                <svg width="1.8em" height="1.8em" viewBox="0 3 18 18" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>                    
           
          </div>    
        
      )
  
    }
    
  }  
  
  
  export default BtnDelete;