import React, {Component} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types'

//Componente para listar en select las Especialidades consumido en API

class Tipo_Afiliacion extends Component {   

    constructor()
    {
     super()
      this.state={        
        afiliacion: [],
        id_afiliacion:''
      }
    }

    componentDidMount(){    
    
        axios.get('https://medicallapi-dev.azurewebsites.net//api/TypeAffiliates/List')
        .then((response) => {
          console.log(response);
          this.setState({afiliacion: response.data})
        })
        .catch((error) => {
          console.log(error);
        })
      } 
      
      handleChange = (e) =>{
        this.setState({id_afiliacion:e.target.value})
        //console.log(e.target.value)
        //var med=(e.target.value)
        //alert(med)
      } 
      

    render(){ 
      const {handleridafiliacion} = this.props  
      const {id_afiliacion} = this.state
   
     return (   
     
        <div >
                <select onChange={this.handleChange} onClick={()=>handleridafiliacion(id_afiliacion)} value={this.state.id_afiliacion}  className="form-control" id="api_tipoafilia">
                    <option  value="null"> Seleccione...</option> ,
                    {this.state.afiliacion.map(item=>                              
                    <option key={item.id} value={item.id}> {item.name}</option>  
                    )}
                            
                </select>    
        </div>    
      
    )

  }
  
  
}  

Tipo_Afiliacion.propTypes = {
  handleridafiliacion:PropTypes.func.isRequired
}


export default Tipo_Afiliacion;