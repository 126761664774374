import React, {Component} from 'react';

class Nuevo extends Component {      

    render(){ 
        
   
     return (   
     
      <svg width="1em" height="1em" viewBox="3 3 10 10" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
      </svg>   
      
    )

  }
  
}  

export default Nuevo;