import React, {Component} from 'react';
import ReactAudioPlayer from 'react-audio-player';
import sonido from '../tools/sound/xiaomi-notifications.mp3';

//Componente para boton editar

class Sound extends Component {   
      

      render(){         
     
        return (   
       
          <ReactAudioPlayer
              src={sonido}
              autoPlay
              
          />   
        
      )
  
    }
    
  }  
  
  
  export default Sound;