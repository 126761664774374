import React, {Component,useState} from 'react';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
registerLocale('es', es);


//Componente para campo fecha


class DataPicker extends Component {

    constructor()
      {
       super()
        this.state={         
          fecha: new Date(),
          date:''
        }        
        
      }
  
      onChange=fech=>{
        this.setState({fecha:fech});
        var date=fech
        this.setState({date})
        alert(date)
        console.log(fech)
      }      

      render(){ 
      const {handlerdate} = this.props  
      const {fecha} = this.state
          
     
       return (   
       
        <div >

           <svg width="1.7em" height="1.7em" viewBox="0 0 16 18" class="bi bi-calendar-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
             <path fill-rule="evenodd" d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
             <path fill-rule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
           </svg>  
           
            <DatePicker dateFormat="dd/MM/yyyy" locale="es" selected={this.state.fecha} onChange={this.onChange} onClick={()=>handlerdate(fecha)} value={this.state.fecha}  />  
           
        </div>    
        
      )
  
    }
    
  }

  DataPicker.propTypes = {
    handlerdate:PropTypes.func.isRequired
  }  
  
  
  export default DataPicker;