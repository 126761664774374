import React, {Component} from 'react';
import PropTypes from 'prop-types'

//Componente para listar en select dias de la semana

class DiasSemana extends Component {   
  
  constructor()
    {
     super()
      this.state={       
        dia:''
      }
    }


    handleChange = (e) =>{
      this.setState({dia:e.target.value})
      //console.log(e.target.value)
      //var med=(e.target.value)
      //alert(med)
    } 
      

    render(){ 
      const {handlerdia} = this.props  
      const {dia} = this.state
        
   
     return (   
     
        <div >

                <select onChange={this.handleChange} onClick={()=>{handlerdia(dia)}} value={this.state.dia}  className="form-control" id="dia">
                    <option  value="null">Seleccione...</option>
                    <option  value="1">Lunes</option>
                    <option  value="2">Martes</option>
                    <option  value="3">Miercoles</option>
                    <option  value="4">Jueves</option>
                    <option  value="5">Viernes</option>
                    <option  value="6">Sábado</option>
                    <option  value="0">Domingo</option>
                    
                </select>                    
         
        </div>    
      
    )

  }
  
}  

DiasSemana.propTypes = {
  handlerdia:PropTypes.func.isRequired
}


export default DiasSemana;