import React, {Component, useRef} from 'react';
import SignatureCanvas from 'react-signature-canvas'
import * as ReactBootStrap from 'react-bootstrap';
import Departamentos from '../API/Departamentos';
import '../tools/css/Activar_membresia.css';
import axios from 'axios';
import img from '../tools/images/logoblanco.png';
import icono_aprobado from '../tools/images/aprobado icono.svg'
import icono_rechazada from '../tools/images/rechazado icono.svg'
import Moment from 'react-moment';
import ReactNotifications from 'react-notifications-component';
import { store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css';

import $ from 'jquery'; // <-to import jquery
import 'bootstrap';
import { FaTextHeight } from 'react-icons/fa';


class Error404 extends Component  { 

  


   render(){  
    

      return(      
        
       
        <div className=""> 

                <nav className="navbar navbar-expand navbar-white navbar-light" id="nav2">       
                

                      <a id="img_center"  href="https://medicall24.com.co/" className="navbar-brand"> <img  src={img} alt="logo"  width= "230"/> </a>
                  
                      <a id="btn-tengocuenta" href="/" target="_blank"> Iniciar sesión </a>
    
                </nav>

                <div className="content-wrapper">
                 
                    <section className="content-header">
                        <div className="container-fluid">
                          <div className="row mb-2">
                            <div className="col-sm-6">
                              <h1>404 Error de pagina</h1>
                            </div>
                            
                          </div>
                        </div>
                    </section>
                 
                    <section className="content">
                        <div className="error-page">
                          <h2 className="headline text-danger"> 404</h2>
                          <div className="error-content">
                            <h3><i className="fas fa-exclamation-triangle text-danger" /> ¡Ups! Página no encontrada.</h3>
                            <p>
                              No pudimos encontrar la página que estabas buscando.
                              Mientras tanto, puedes <a id="btn-volverinicio" href="/home">volver al inicio</a> o visita nuestra pagina web <a id="btn-volverinicio" href="https://medicall24.com.co/">medicall24.com.co</a>
                            </p>
                            {/* <form className="search-form">
                              <div className="input-group">
                                <input type="text" name="search" className="form-control" placeholder="Search" />
                                <div className="input-group-append">
                                  <button type="submit" name="submit" className="btn btn-warning"><i className="fas fa-search" />
                                  </button>
                                </div>
                              </div>
                            
                            </form> */}
                          </div>
                        
                        </div>   
                    </section>                 
                </div>
        </div>
        
         
      )

  }  
  
  
}






export default Error404;