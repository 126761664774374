import React, {Component} from 'react';
import PropTypes from 'prop-types'

//Componente para listar en select tipo de ID

class TipoID extends Component {   
  
  constructor()
    {
     super()
      this.state={       
        id_tipoid:''
      }
    }


    handleChange = (e) =>{
      this.setState({id_tipoid:e.target.value})
      //console.log(e.target.value)
      //var med=(e.target.value)
      //alert(med)
    } 
      

    render(){ 
      const {handleridtipoid} = this.props  
      const {id_tipoid} = this.state
        
   
     return (   
     
        <div >

                <select onChange={this.handleChange} onClick={()=>handleridtipoid(id_tipoid)} value={this.state.id_tipoid}  className="form-control" id="id_tipoid">
                    <option  value="null">Tipo de identificación</option>
                    <option  value="CC">Cédula de Ciudadanía</option>
                    <option  value="RC">Registro Civil</option>
                    <option  value="TI">Tarjeta de Identidad</option>
                    <option  value="CE">Cédula de Extranjería</option>
                    <option  value="DNI">Documento Nacional de ID</option>
                    <option  value="PPT">Pasaporte</option>
                    <option  value="CDIP">Carnet Diplomático</option>
                    <option  value="PEP">Permiso Especial de Permanencia</option>
                   
                </select>                    
         
        </div>    
      
    )

  }
  
}  

TipoID.propTypes = {
  handleridtipoid:PropTypes.func.isRequired
}


export default TipoID;